import Chip, { ChipProps } from '@mui/material/Chip';
import { system } from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';

export interface PillProps extends ChipProps {
  selected?: boolean;
}

const selectedState = css`
  background-color: ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};
  border: 1px solid ${(p) => p.theme.midwestColors.identityBrandPrimaryMedium};

  .MuiChip-label {
    color: ${(p) => p.theme.midwestColors.backgroundPrimary};
  }
`;

const StyledPill = styled(Chip)`
  &.MuiChip-root {
    background-color: ${(p) => p.theme.midwestColors.identityBrandPrimaryFaint};
    border-radius: 0.75rem;
    padding-top: 0.69rem;
    padding-bottom: 0.56rem;

    &.MuiChip-clickable:hover {
      background-color: ${(p) =>
        p.theme.midwestColors.identityBrandPrimaryWeak};
    }
    /* stylelint-disable-next-line block-no-empty -- Disabled during migration to Stylelint 15.  Please Fix.*/
    &.MuiChip-clickable:focus {
      ${selectedState}
    }
  }
  .MuiChip-label {
    color: ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
    font-size: 0.75rem;
    font-weight: ${system.fontWeight['primary']['medium'].value};
    line-height: ${system.lineHeight['lg'].value};
  }
  /* stylelint-disable-next-line block-no-empty -- Disabled during migration to Stylelint 15.  Please Fix.*/
  &.MuiChip-filled {
    ${selectedState}
  }
  &.MuiChip-outlined {
    border: 1px solid ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};
  }
`;

export const Pill = ({ children, selected, ...props }: PillProps) => (
  <StyledPill
    onDelete={undefined}
    variant={selected ? 'filled' : 'outlined'}
    clickable
    {...props}
  >
    {children}
  </StyledPill>
);
